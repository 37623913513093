import { LitElement, html, svg } from 'lit'
import { capFirst, randomInt } from '#js/components/utils'
import { fetchJSON, formPost } from '#js/components/http'
import { msg, updateWhenLocaleChanges } from '@lit/localize'
import { classMap } from 'lit/directives/class-map.js'
import { setLocale } from '#js/components/lit-i18n'

export class MovingBlob {
  constructor (parent, radius, centerX, centerY, delta = 0.05, apexes = 7, duration = 5000, jitter = 1000) {
    this.parent = parent
    this.radius = radius
    this.centerX = centerX
    this.centerY = centerY
    this.delta = delta
    this.apexes = apexes
    this.duration = duration
    this.jitter = jitter

    this.update()

    // trigger first update after 100ms to start the css transition
    setTimeout(
      () => {
        this.d = this.drawBezierBlob(this.radius)
        this.parent.requestUpdate()
      }, 100
    )
  }

  update () {
    this.d = this.drawBezierBlob()
    this.parent.requestUpdate()
    setTimeout(() => this.update(), randomInt(this.duration - this.jitter, this.duration + this.jitter)
    )
  }

  getRandomRadiusModifier () {
    let num = Math.floor(Math.random() * this.delta * this.radius) + 1
    num *= Math.floor(Math.random() * 2) === 1 ? 1 : -1
    return num
  }

  /**
   *  Generate Catmull-Rom coordinates for a polygon with n apexes in a circle.
   *  @returns {Array} pathCoordinates - an array of objects with x and y coordinates
   */
  generateCoords () {
    const pathCoordinates = []
    const rad = 2 * Math.PI / this.apexes
    for (const i of Array(this.apexes).keys()) {
      const x = (this.radius * Math.cos(rad * i) + this.centerX) + this.getRandomRadiusModifier()
      const y = (this.radius * Math.sin(rad * i) + this.centerY) + this.getRandomRadiusModifier()
      pathCoordinates.push({ x, y })
    }
    // insert the start coordinates to close the circle
    pathCoordinates.push(pathCoordinates[0])
    return pathCoordinates
  }

  /**
   * Return a random bezier-curve with the properties of a circle.
   *
   * Generate Catmull-Rom coordinates for a polygon with n apexes in a circle.
   * Calculate the control points for each apex and draw a bezier-curve where
   * both ends meet.
   * @returns {string} - A string with the bezier-curve coordinates.
   */
  drawBezierBlob () {
    const pathCoordinates = this.generateCoords()
    let d = ''
    pathCoordinates.forEach((coord, index, array) => {
      const p = []
      if (index === 0) {
        d += `M${coord.x},${coord.y} `
        p.push(array[array.length - 3])
        p.push(array[index])
        p.push(array[index + 1])
        p.push(array[index + 2])
      } else if (index === array.length - 2) {
        p.push(array[index - 1])
        p.push(array[index])
        p.push(array[index + 1])
        p.push(array[0])
      } else if (index === array.length - 1) {
        return
      } else {
        p.push(array[index - 1])
        p.push(array[index])
        p.push(array[index + 1])
        p.push(array[index + 2])
      }
      const bp = []
      bp.push({ x: p[1].x, y: p[1].y })
      bp.push({ x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6), y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6) })
      bp.push({ x: ((p[1].x + 6 * p[2].x - p[3].x) / 6), y: ((p[1].y + 6 * p[2].y - p[3].y) / 6) })
      bp.push({ x: p[2].x, y: p[2].y })
      d += 'C' + bp[1].x + ',' + bp[1].y + ' ' + bp[2].x + ',' + bp[2].y + ' ' + bp[3].x + ',' + bp[3].y + ' '
    })

    return d
  }
}

export class BreathExercise extends LitElement {
  static get properties () {
    return {
      size: { type: Number },
      hue: { type: Number },
      alarmSound: { type: String },
      alarmTimeout: { type: Number },
      alarmInterval: { type: Number },
      alarmCallbackUrl: { type: URL },
      backgroundSound: { type: String },
      breathe: { type: Boolean, attribute: false },
      started: { type: Boolean, attribute: false },
      blobs: { type: Array, attribute: false }
    }
  }

  constructor () {
    super()

    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)

    this.status = 'cta'
    this.size = 500
    this.breathe = true
    this.centerX = this.size / 2
    this.centerY = this.size / 2
    const radius = this.size / 2 * 0.8
    this.completed = false
    this.hue = randomInt(0, 360)
    this.apexes = 7
    this.alarmInterval = 300
    this.blobs = [
      new MovingBlob(this, radius, this.centerX, this.centerY),
      new MovingBlob(this, radius * 0.9, this.centerX, this.centerY),
      new MovingBlob(this, radius * 0.8, this.centerX, this.centerY)
    ]
  }

  async start () {
    if (this.status !== 'cta') {
      console.debug('Breath exercise already started')
      return // already started
    }
    const startTime = new Date()
    try {
      const response = await formPost(this.alarmCallbackUrl)
      const data = await response.json()
      console.debug('Track exercise', data)
      setInterval(() => {
        fetchJSON(this.alarmCallbackUrl, { method: 'PATCH', body: JSON.stringify({ id: data.id, duration: new Date() - startTime }) }).then(
          (response) => console.debug('Update play time', response)
        ).catch(
          (error) => console.error('Failed to call alarm callback', error)
        )
      }, 15 * 1000) // send a ping every 15 seconds
    } catch (error) {
      console.error('Failed to call alarm callback', error)
    }
    this.status = 'get-ready'
    try {
      await navigator.wakeLock.request('screen')
    } catch (err) {
      // the wake lock request fails - usually system related, such being low on battery
      console.warn(`${err.name}, ${err.message}`)
    }
    this.breatheOut()
    setTimeout(function () {
      this.startTimer()
      this.playSound()
    }.bind(this), 250)
    setTimeout(() => {
      this.status = 'intro'
      setTimeout(() => {
        this.status = 'breathe'
        setTimeout(() => {
          this.status = 'close-your-eyes'
          setTimeout(() => {
            this.status = 'eyes-closed'
          }, 10000)
        }, 40000)
      }, 10000)
    }, 10000)
  }

  async playSound () {
    console.debug(`Playing background sound: ${this.backgroundSound}`)
    const audio = new Audio(this.backgroundSound)
    audio.loop = true
    audio.volume = 0.5
    audio.addEventListener('canplaythrough', async (event) => {
      await audio.play()
    })
  }

  async startTimer () {
    console.debug(`starting timer for ${this.alarmTimeout} seconds`)
    const alarm = new Audio(this.alarmSound)
    alarm.addEventListener('canplaythrough', async (event) => {
      await alarm.play()
    })
    setTimeout(async () => {
      console.debug('timer finished')
      this.status = 'done'
      await alarm.play()
      setInterval(async () => {
        console.debug('timer snoozed')
        await alarm.play()
      }, 1000 * this.alarmInterval)
    }, 1000 * this.alarmTimeout)
  }

  breatheIn () {
    console.debug('Breathe in')
    this.breathe = true
    setTimeout(function () {
      this.breatheOut()
    }.bind(this), 4000)
  }

  breatheOut () {
    console.debug('Breathe out')
    this.breathe = false
    setTimeout(function () {
      this.breatheIn()
    }.bind(this), 6000)
  }

  getText () {
    switch (this.status) {
      case 'cta':
        return svg`
          <text x="50%" y="50%" @click="${this.start}" style="cursor: pointer">${capFirst(msg('click to start'))}</text>`
      case 'get-ready':
        return svg`<text class="fade-in-out" style="animation-duration: 10s" x="50%" y="50%">${capFirst(msg('get comfortable'))}</text>`
      case 'intro':
        return svg`<text class="fade-in-out" style="animation-duration: 10s" x="50%" y="50%">${capFirst(msg('breathe'))}</text>`
      case 'close-your-eyes':
        return svg`<text class="fade-in-out" style="animation-duration: 10s" x="50%" y="50%">${capFirst(msg('close your eyes'))}</text>`
      case 'eyes-closed':
        return ''
      case 'done':
        return svg`<text class="fade-in" x="50%" y="50%">${msg('the end')}</text>`
      default: // in/out
        if (this.breathe) {
          return svg`<text class="fade-in-out" style="animation-duration: 4s" x="50%" y="50%">${msg('in')}</text>`
        } else {
          return svg`<text class="fade-in-out" style="animation-duration: 6s" x="50%" y="50%">${msg('out')}</text>`
        }
    }
  }

  render () {
    return html`
      <svg width="100%" height="100%" viewBox="0 0 ${this.size} ${this.size}" xmlns="http://www.w3.org/2000/svg">
        <style>
          :host {
            background: hsla(${this.hue}, 100%, 50%, 0.1);
            display: block;
            width: 100vw;
            height: 100vh;
          }
          path {
            transition: all 5000ms ease-in-out;
            border: none;
          }

          g {
            transform-origin: center;
          }

          .breath-in {
            transition: all 4000ms ease-in-out;
            transform-origin: center;
            scale: 1;
          }
          .breath-out {
            transition: all 6000ms ease-out;
            transform-origin: center;
            scale: 0.7;
          }

          @keyframes fade-in-out {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }

          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }


          text {
            font-size: 2rem;
            fill: hsl(${this.hue}, 100%, 10%);
            text-anchor: middle;
            dominant-baseline: middle;
            cursor: default;
          }

          text.fade-in-out {
            opacity: 0;
            animation-name: fade-in-out;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
          }

          text.fade-in {
            opacity: 1;
            animation-name: fade-in;
            animation-duration: 2s;
            animation-timing-function: ease-in;
            animation-iteration-count: 1;
          }
        </style>
        <defs>
          <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:hsl(${this.hue},70%,55%);stop-opacity:1" />
            <stop offset="100%" style="stop-color:hsl(${this.hue},70%,35%);stop-opacity:1" />
          </linearGradient>
        </defs>
        <g class="${classMap({ 'breath-in': this.breathe, 'breath-out': !this.breathe })}" @click="${this.start}" style="${this.status === 'cta' ? 'cursor:pointer' : ''}">
          ${this.blobs.map((blob, i) => svg`
            <path d="${blob.d}" fill="url(#grad)" opacity="${0.4 + (i / 5)}" />
          `)}
        </g>
        ${this.getText()}
      </svg>
    `
  }
}

window.customElements.define('breath-exercise', BreathExercise)
